<template>
  <main class="flex flex-column align-items-center bg-gradient">
    <section id="title" class="pt-0 lg:pt-7 w-11 lg:w-10 relative">
      <div class="grid grid-nogutter justify-content-around">
        <div class="col-12 lg:col-6 flex flex-column justify-content-center">
          <h1 class="mt-6 lg:my-0 text-center lg:text-left">
            <span class="title-underline">Libérez-vous</span> du RGPD avec Blockproof
          </h1>
          <p class="mt-4 text-center lg:text-left">
            Le RGPD est vite perçu comme une contrainte (manque de temps, incertitude, risque de sanctions...).
            Trouvez la solution adaptée avec Blockproof.
          </p>
          <div class="mt-5 text-center lg:text-left">
            <PrimeButton label="Nous contacter"
                         raised size="large"
                         class="text-sm lg:text-lg ga-button-contact"
                         @click="$emitter.emit('contactModalEvent', {show: true})"/>
          </div>
        </div>

        <div class="col-12 mt-4 lg:mt-0 lg:col-5 lg:pt-6">
          <PrimeImage src="/img/illustrations/illustration-1.svg" alt="blockproof"
                      imageClass="max-w-full max-h-30rem"></PrimeImage>
          <!--                      imageClass="max-w-full max-h-30rem"></PrimeImage>-->
        </div>
      </div>
    </section>


    <!--  Partners -->
    <section class="mt-7 lg:mt-10 w-11 relative">
      <div class="text-center">
        <PrimeImage src="/img/partners/logos-partners.webp" alt="partners" imageClass="max-w-full"
                    class="hidden lg:block"></PrimeImage>
        <PrimeImage src="/img/partners/logo-partners-phone.webp" alt="partners" imageClass="max-w-full"
                    class="lg:hidden"></PrimeImage>
      </div>
    </section>


    <!-- Plans -->
    <section class="mt-8 w-10 lg:w-10 relative">
      <div class="text-center">
        <h2>Nos offres de conformité RGPD</h2>
      </div>

      <!-- Plan : DPO externe -->
      <div class="mt-0 lg:mt-5 grid justify-content-center">
        <div class="col-12 lg:col-5 text-center">
          <NuxtImg src="/img/illustrations/illustration-2.svg" alt="DPO interne" class="img-responsive lg:h-25rem"
                   loading="lazy"/>
          <div class="mt-2 text-lg lg:text-2xl font-semibold text-primary">DPO externe</div>
          <div class="mt-2 text-sm lg:text-lg font-medium text-gray-700 quotes">Blockproof s'occupe de tout</div>
        </div>

        <div class="col-12 lg:col-5 flex flex-column justify-content-center">
          <h3 class="hidden lg:block">Votre Partenaire RGPD tout-en-un</h3>

          <ul class="lg:text-2xl line-height-4 font-normal">
            <li>
              <i class="pi pi-chevron-circle-right text-sm lg:text-lg text-primary"></i>
              <span class="ml-3">DPO externe auprès de la CNIL</span>
            </li>
            <li>
              <i class="pi pi-chevron-circle-right text-sm lg:text-lg text-primary"></i>
              <span class="ml-3">Mise en place de la conformité</span>
            </li>
            <li>
              <i class="pi pi-chevron-circle-right text-sm lg:text-lg text-primary"></i>
              <span class="ml-3">Formations RGPD</span>
            </li>
            <li>
              <i class="pi pi-chevron-circle-right text-sm lg:text-lg text-primary"></i>
              <span class="ml-3">Mises à jour et Audits de maturité</span>
            </li>
          </ul>

          <div class="mt-4 text-center lg:text-left">
            <PrimeButton label="Demander un devis" raised size="large"
                         class="text-sm lg:text-lg ga-button-contact"
                         @click="$emitter.emit('contactModalEvent', {show: true})"/>
          </div>
        </div>
      </div>


      <!-- Plan : DPO interne -->
      <div class="mt-10 lg:mt-5 grid justify-content-center">
        <div class="col-12 lg:col-5 flex flex-column justify-content-center flex-order-1 lg:flex-order-0">
          <h3 class="hidden lg:block">Le support idéal au DPO interne</h3>

          <ul class="lg:text-2xl line-height-4 font-normal">
            <li>
              <i class="pi pi-chevron-circle-right text-sm lg:text-lg text-primary"></i>
              <span class="ml-3">Formations RGPD</span>
            </li>
            <li>
              <i class="pi pi-chevron-circle-right text-sm lg:text-lg text-primary"></i>
              <span class="ml-3">Accompagnement opérationnel</span>
            </li>
            <li>
              <i class="pi pi-chevron-circle-right text-sm lg:text-lg text-primary"></i>
              <span class="ml-3">Logiciel RGPD intuitif</span>
            </li>
            <li>
              <i class="pi pi-chevron-circle-right text-sm lg:text-lg text-primary"></i>
              <span class="ml-3">Catalogue RGPD à la carte</span>
            </li>
          </ul>

          <div class="mt-4 text-center lg:text-left">
            <PrimeButton label="Demander un devis" raised size="large"
                         class="text-sm lg:text-lg ga-button-contact"
                         @click="$emitter.emit('contactModalEvent', {show: true})"/>
          </div>
        </div>

        <div class="col-12 lg:col-5 text-center">
          <NuxtImg src="/img/illustrations/illustration-3.svg" alt="DPO interne" class="img-responsive lg:h-25rem"
                   loading="lazy"/>
          <div class="mt-2 text-lg lg:text-2xl font-semibold text-primary">Support DPO interne</div>
          <div class="mt-2 text-sm lg:text-lg font-medium text-gray-700 quotes">Blockproof vous guide dans<br> votre
            conformité
          </div>
        </div>
      </div>
    </section>


    <section class="mt-8 w-10 lg:w-10 relative">
      <div class="text-center">
        <h2>Pourquoi choisir Blockproof ?</h2>
      </div>

      <!-- Benefits -->
      <div class="lg:mt-7 grid justify-content-center">
        <div class="hidden lg:block col-12 lg:col-5 text-center">
          <NuxtImg format="webp" src="/img/benefits.webp" alt="Avantages"
                   class="max-w-full h-20rem"
                   loading="lazy"/>
        </div>

        <div class="col-12 lg:col-5 lg:pl-5 flex flex-column justify-content-around">
          <!--          <div class="flex align-items-center">-->
          <!--            <i class="pi pi-chevron-circle-right text-xl text-primary"></i>-->
          <!--            <div class="ml-5">-->
          <!--              <div class="text-2xl font-normal text-primary">Une méthode adaptée à vos métiers</div>-->
          <!--              <div>validée par 900+ établissements</div>-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="">
            <div class="text-lg lg:text-2xl font-medium text-primary">Une méthode adaptée à vos métiers</div>
            <div class="lg:mt-2">validée par 900+ établissements</div>
          </div>

          <div class="mt-5">
            <div class="text-lg lg:text-2xl font-medium text-primary">Evitez à vos équipes de perdre du temps</div>
            <div class="lg:mt-2">concentrez-vous sur votre métier</div>
          </div>

          <div class="mt-5">
            <div class="text-lg lg:text-2xl font-medium text-primary">Supprimez l’incertitude</div>
            <div class="lg:mt-2">accompagnement personnalisé et sécurité juridique</div>
          </div>
        </div>

      </div>
    </section>


    <!--  Partners -->
    <section class="hidden lg:block mt-10 w-11 relative">
      <div class="text-center">
        <PrimeImage src="/img/partners/logos-partners.webp" alt="partners" imageClass="max-w-full"
                    class="hidden lg:block"></PrimeImage>
        <PrimeImage src="/img/partners/logo-partners-phone.webp" alt="partners" imageClass="max-w-full"
                    class="lg:hidden"></PrimeImage>
      </div>
    </section>


    <!-- Testimonials -->
    <section class="mt-5 lg:mt-6 w-11 lg:w-10 relative">
      <TestimonialsCards></TestimonialsCards>
    </section>


    <!-- Localizations -->
    <section class="mt-8 w-11 lg:w-10 relative">
      <div class="text-center">
        <h2>Blockproof est partout<br>où vous êtes</h2>
      </div>

      <div class="mt-3 lg:mt-5 grid justify-content-center">
        <div class="col-12 lg:col-5 text-center">
          <NuxtImg src="/img/illustrations/illustration-4.svg" alt="Travail à distance" class="sm:h-8rem lg:h-25rem"
                   loading="lazy"/>
        </div>

        <div class="col-12 lg:col-5 xl:col-4 lg:pl-5 flex flex-column justify-content-center">
          <div class="text-lg lg:text-2xl line-height-3 text-center lg:text-left">
            Blockproof vous accompagne en France métropolitaine, en France Outre-Mer, en Belgique et au Luxembourg.
          </div>

          <div class="mt-4 lg:mt-6 text-center lg:text-left">
            <PrimeButton label="Contactez-nous" raised size="large"
                         class="text-sm lg:text-lg ga-button-contact"
                         @click="$emitter.emit('contactModalEvent', {show: true})"/>
          </div>
        </div>
      </div>
    </section>


    <!-- Explorer -->
    <section id="section-explore" class="mt-8 w-11 lg:w-10 relative">
      <div class="text-center">
        <h2>Explorez le RGPD avec Blockproof</h2>
      </div>

      <div class="mt-5 grid">
        <!-- Podcast -->
        <div class="mt-3 col-12 lg:col-3 px-3 explore-card">
          <PrimeCard class="mx-auto max-w-full w-20rem h-full border-round-3xl shadow-2">
            <template #content>
              <div
                  class="p-3 lg:p-5 flex justify-content-center align-content-center bg-primary-50 text-center line-height-3">
                <span class="text-primary text-lg lg:text-2xl font-normal">Podcasts<br>RGPD</span>
              </div>
              <div
                  class="p-3 lg:p-5 flex-1 flex flex-column justify-content-between align-content-center text-sm lg:text-xl text-center">
                <div class="pb-3">Discussions entre DPO et actualités</div>
                <a href="https://podcast.ausha.co/in-my-data" target="_blank" rel="external">
                  <PrimeButton label="Découvrir"
                               raised size="large"
                               class="text-sm lg:text-lg"/>
                </a>
              </div>
            </template>
          </PrimeCard>
        </div>

        <!-- Sensibilisation -->
        <div class="mt-3 col-12 lg:col-3 px-3 explore-card">
          <PrimeCard class="mx-auto max-w-full w-20rem h-full border-round-3xl shadow-2">
            <template #content>
              <div
                  class="p-3 lg:p-5 flex justify-content-center align-content-center bg-primary-50 text-center line-height-3">
                <span class="text-primary text-lg lg:text-2xl font-normal">Sensibilisation<br>salariés</span>
              </div>
              <div
                  class="p-3 lg:p-5 flex-1 flex flex-column justify-content-between align-content-center text-sm lg:text-xl text-center">
                <div class="pb-3">Serious game RGPD et Cybersécurité</div>
                <NuxtLink to="/formations" target="_blank">
                  <PrimeButton label="Consulter"
                               raised size="large"
                               class="text-sm lg:text-lg"/>
                </NuxtLink>
              </div>
            </template>
          </PrimeCard>
        </div>

        <!-- Formation -->
        <div class="mt-3 col-12 lg:col-3 px-3 explore-card">
          <PrimeCard class="mx-auto max-w-full w-20rem h-full border-round-3xl shadow-2">
            <template #content>
              <div
                  class="p-3 lg:p-5 flex justify-content-center align-content-center bg-primary-50 text-center line-height-3">
                <span class="text-primary text-lg lg:text-2xl font-normal">Formations<br>RGPD</span>
              </div>
              <div
                  class="p-3 lg:p-5 flex-1 flex flex-column justify-content-between align-content-center text-sm lg:text-xl text-center">
                <div class="pb-3">Ateliers PIA, Privacy by Design, Audit</div>
                <NuxtLink to="/formations" target="_blank">
                  <PrimeButton label="En savoir plus"
                               raised size="large"
                               class="text-sm lg:text-lg"/>
                </NuxtLink>
              </div>
            </template>
          </PrimeCard>
        </div>

        <!-- Blog -->
        <div class="mt-3 col-12 lg:col-3 px-3 explore-card">
          <PrimeCard class="mx-auto max-w-full w-20rem h-full border-round-3xl shadow-2">
            <template #content>
              <div
                  class="p-3 lg:p-5 flex justify-content-center align-content-center bg-primary-50 text-center line-height-3">
                <span class="text-primary text-lg lg:text-2xl font-normal">Articles<br>RGPD</span>
              </div>
              <div
                  class="p-3 lg:p-5 flex-1 flex flex-column justify-content-between align-content-center text-sm lg:text-xl text-center">
                <div class="pb-3">RH, Géolocalisation, Données sensibles...</div>
                <NuxtLink to="/blog" target="_blank">
                  <PrimeButton label="Feuilleter"
                               raised size="large"
                               class="text-sm lg:text-lg"/>
                </NuxtLink>
              </div>
            </template>
          </PrimeCard>
        </div>
      </div>
    </section>


    <section class="mt-10 w-11">
      <QualiopiBanner></QualiopiBanner>
    </section>
  </main>
</template>


<script setup lang="ts">
import {useHead} from '#imports';
import {onMounted} from 'vue';


// ## Event
const {$emitter} = useNuxtApp();


const feedbacks = [
  {
    author_img: 'https://randomuser.me/api/portraits/women/36.jpg',
    feedback_text: 'Logiciel intuitif, agréable, rapide...il fait le job',
    author_name: 'Laura, DPO'
  },
  {
    author_img: 'https://randomuser.me/api/portraits/men/31.jpg',
    feedback_text: 'L’équipe Blockproof a tenu sa promesse : la prise en main du logiciel se fait rapidement et leur support client est très réactif.',
    author_name: 'Pierre, Manager PME'
  },
  {
    author_img: 'https://randomuser.me/api/portraits/women/81.jpg',
    feedback_text: 'L‘outil est très utile et intéressant = gain de temps garanti.',
    author_name: 'Aurélie, Directrice TPE'
  },
]


useHead({
  title: 'Blockproof Conformité RGPD',
  meta: [
    {
      hid: 'og:title',
      property: 'og:title',
      content: 'Blockproof Prestataire RGPD',
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content: 'Prestataire RGPD avec un accompagnement juridique et un logiciel RGPD pour votre mise en conformité.'
    },
    {
      hid: 'description',
      name: 'description',
      content: 'Prestataire RGPD avec un accompagnement juridique et un logiciel RGPD pour votre mise en conformité.'
    }
  ],
  script: [
    {
      hid: 'structured-data',
      type: 'application/ld+json',
      innerHTML: JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'WebApplication',
        'name': 'Blockproof',
        'applicationCategory': 'Logiciel RGPD',
        'image': 'https://blockproof.fr/img/blockproof-google.webp',
        'url': 'https://blockproof.fr/',
        'aggregateRating': {
          '@type': 'AggregateRating',
          'ratingValue': '4.8',
          'reviewCount': '52'
        }
        // "offers": [
        //   {
        //     "@type": "Offer",
        //     "name": "Standard",
        //     "image": "https://blockproof.fr/img/illustrations/illustrations-01-2293%E2%80%8A_1688@0.5_.jpg",
        //     "url": "https://blockproof.fr/devis-rgpd",
        //     "priceSpecification" : {
        //       "priceCurrency" : "EUR",
        //       "minPrice" : "1090"
        //     }
        // ]
      })
    }
  ]
})


onMounted(() => {
  // --- Open Medico-Social Modal
  // let openMedicoSocialModal = sessionStorage.getItem('openMedicoSocialModal') || 'true';
  //
  // if (openMedicoSocialModal === 'true') {
  //   const offsetHeight = document.getElementById('escort').offsetHeight;
  //
  //   $(window).bind('scroll', () => {
  //     let offset = $(document).scrollTop();
  //     if (offset > offsetHeight && openMedicoSocialModal === 'true') {
  //       this.$root.$emit('openMedicoSocialModal');
  //       openMedicoSocialModal = 'false';
  //       sessionStorage.setItem('openMedicoSocialModal', 'false');
  //     }
  //   });
  // }
})
</script>


<style lang="scss" scoped>
// Explore
.explore-card {
  ::v-deep(.p-card) {
    overflow: hidden;

    .p-card-body {
      padding: 0;
    }

    .p-card-body, .p-card-content {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
